import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import {
  EmptySearch,
  IconWrap,
  InputWrap,
  SearchResult,
  SearchResultItem,
  SearchResultList,
  SearchResultProductsItem,
  SearchResultProductsList,
  SearchShopResultItem,
  Wrapper
} from './SearchInput.styled';

import { useClickOutside } from '../../../../hooks/common/useClickOutside';
import useMatchMedia from '../../../../hooks/common/useMatchMedia';
import { useAddToCart } from '../../../../hooks/mutations/cart/useCartMutations';
import { useCartQuery } from '../../../../hooks/queries/cart/useCartQuery';
import { useProductsSearchQuery } from '../../../../hooks/queries/products/useProductsSearchQuery';
import { getRouteSearch } from '../../../../shared/const';
import { cn } from '../../../../shared/lib/classnames.helper';
import { CartItem } from '../../../../types/CartPageTypes';
import { generateRandomString, getCountSearchItems } from '../../../../utils/helperFunctions';
import { FlexWithAlign, ImgWrap, Text14 } from '../../../../utils/styleUtils';
import { EIcon, Icon } from '../../../icons/icon';
import ProductButton from '../../buttons/product/ProductButton';
import { useActiveAddressQuery } from '../../../../hooks/queries/profile/useProfileQuery';
import { GEO } from '../../../../shared/const/common.const';

interface ISearchInput {
  className?: string;
}

const SearchInput: React.FC<ISearchInput> = (props) => {
  const { className } = props;
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { data: address } = useActiveAddressQuery();
  const { data: cart } = useCartQuery({ geo: address?.geo ? address?.geo : GEO });
  const { mutate: add } = useAddToCart();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const nav = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
    setValue(e.target.value);
  };
  const handleFocus = () => {
    setIsFocused(true);
    setIsShow(true);
  };
  // const handleBlur = () => {
  //   setIsFocused(false);
  // };
  const handleClear = () => {
    setValue('');
    setIsFocused(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const handleFollowLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsShow(false);
    nav(`${getRouteSearch()}?q=${value}`);
  };
  const { data } = useProductsSearchQuery(value);
  const { ref, isShow, setIsShow } = useClickOutside(true);
  const nodeRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleProductClick = (data: any, product: any) => {
    nav(`/shops/${data.shop.section_id}/catalog/${data.shop.id}/category/${product.category_id}#${product.id}`);
    setIsShow(false);
    setValue('');
  };
  const handleShopClick = (data: any) => {
    nav(`/shops/${data.shop.section_id}/catalog/${data.shop.id}`);
    setIsShow(false);
    setValue('');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsFocused(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Wrapper
      ref={ref}
      className={cn(isFocused ? 'focused' : '', className && `${className}`)}
    >
      <IconWrap>
        <Icon name={EIcon.search} />
      </IconWrap>
      <InputWrap
        type='search'
        ref={inputRef}
        placeholder='Введите название магазина, товара'
        onChange={handleChange}
        onFocus={handleFocus}
        // onBlur={handleBlur}
        value={value || ''}
        className={isFocused ? 'focused' : ''}
      ></InputWrap>
      {value !== '' && (
        <IconWrap
          className='clear-search'
          onClick={handleClear}
        >
          <Icon name={EIcon.clearsearch} />
        </IconWrap>
      )}

      {data && value !== '' && (
        <CSSTransition
          nodeRef={nodeRef}
          in={isShow}
          timeout={1000}
          classNames='example'
          unmountOnExit
          mountOnEnter
        >
          <SearchResult
            ref={nodeRef}
            className='search-result'
          >
            {/* {data.filter((item: any) => item.products.length > 0).length > 0 ? ( */}
            {data.sort((a: any, b: any) => {
              return a.products.length - b.products.length;
            }).length > 0 ? (
              <SearchResultList>
                {
                  // {const countProducts = 0}
                  data.map((results: any) => (
                    <>
                      {results.products.length === 0 ? (
                        <SearchShopResultItem
                          className={'shop-result-item'}
                          key={generateRandomString()}
                        >
                          <span className='img flex'>
                            {results.shop.background_image ? (
                              <img src={`${process.env.REACT_APP_API_HOST}${results.shop.background_image}`} />
                            ) : (
                              <span className='without-img flex'>{results.shop.name[0]}</span>
                            )}
                          </span>
                          <span className={'shop-name'}>«{results.shop.name}»</span>
                          <button
                            className={'shop-link'}
                            onClick={() => handleShopClick(results)}
                          >
                            {!isMobile && <span>В магазин</span>}

                            <span className='flex'>
                              <Icon name={EIcon.backarrow} />
                            </span>
                          </button>
                        </SearchShopResultItem>
                      ) : (
                        <SearchResultItem key={generateRandomString()}>
                          <Text14 $color={'#6E6E6E'}>«{results.shop.name}»</Text14>

                          <SearchResultProductsList>
                            {results.products.slice(0, 5).map((product: any) => (
                              <SearchResultProductsItem
                                key={generateRandomString()}
                                onClick={() => {
                                  handleProductClick(results, product);
                                }}
                              >
                                <span className='img'>
                                  <ImgWrap
                                    src={`${process.env.REACT_APP_API_HOST}${product.image}`}
                                    alt={product.name}
                                    $borderRadius={'8px'}
                                  />
                                </span>
                                <FlexWithAlign
                                  $column
                                  $gap={'4px'}
                                  className={'product-name-price-wrap'}
                                >
                                  <span className={'product-name'}>{product.name}</span>
                                  <FlexWithAlign
                                    $gap={'26px'}
                                    $align={'center'}
                                  >
                                    <span className={'product-price'}>{Number(product.price || '0').toFixed()} ₽</span>
                                    <span className={'product-unit'}>{product.type === 'weight' ? '1 кг' : '1 шт'}</span>
                                  </FlexWithAlign>
                                </FlexWithAlign>
                                <div
                                  className='add-to-cart flex'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {product.id && cart && (Object.keys(cart).length == 0 || Object.keys(cart).length > 0) ? (
                                    Object.keys(cart).length > 0 &&
                                    cart?.items.findIndex((item: CartItem) => item.product_id === product.id) > -1 ? (
                                      <ProductButton
                                        className={'product-cart-btn'}
                                        product={cart?.items.find((item: CartItem) => item.product_id === product.id)}
                                      />
                                    ) : (
                                      <button
                                        className={'add-btn'}
                                        type={'button'}
                                        onClick={() => add({ id: product.id, geo: address?.geo ? address?.geo : GEO, quantity: 1 })}
                                        disabled={
                                          cart && Object.keys(cart).length > 0 && cart.items.length > 0 && cart.shop_id !== product.shop_id
                                        }
                                      >
                                        <Icon name={EIcon.cart} />
                                      </button>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </SearchResultProductsItem>
                            ))}
                          </SearchResultProductsList>
                        </SearchResultItem>
                      )}
                    </>
                  ))
                }
                {getCountSearchItems(data) > 5 && getCountSearchItems(data) > 0 && (
                  <Link
                    to={getRouteSearch()}
                    onClick={(e) => handleFollowLink(e)}
                    className={'all-results flex'}
                  >
                    <Text14>{`Показать все ${getCountSearchItems(data)} результат(а/ов)`}</Text14>
                  </Link>
                )}
              </SearchResultList>
            ) : (
              <EmptySearch className='flex'>
                <span>
                  <Icon name={EIcon.searchlogo} />
                </span>
                <span>К сожалению, по вашему запросу ничего не найдено</span>
              </EmptySearch>
            )}
          </SearchResult>
        </CSSTransition>
      )}
    </Wrapper>
  );
};

export default SearchInput;
