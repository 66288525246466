export const USER_SESSION = 'user_session';

export const PROFILE = 'profile';
export const PROFILE_ADDRESS = 'profile_address';
export const PROFILE_ACTIVE_ADDRESS = 'profile_active_address';

export const MARKETS = 'markets';
export const HOME = 'home';
export const SECTIONS = 'sections';
export const SECTIONS_ALL = 'sections_all';

export const SHOPS = 'shops';
export const CATALOG = 'catalog';
export const CATALOG_CATEGORY = 'catalog_category';

export const CATEGORY = 'category';

export const CART = 'cart';

export const PRODUCTS_SEARCH = 'products_search';

export const ORDERS = 'orders';

export const ORDER = 'order';
export const ORDER_TIME = 'order_time';

export const CHATQUERY = 'chat';
export const ADDSUPPORTQUERY = 'add-support-query';
